/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";


$green: #97FF8F;
$yellow: #FA9917;
$yellow: #F5AB24;

.green-color {
    color: $green;
}

.yellow-color,
.yellow-light-color {
    color: $yellow;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

input,
select {
    border: 0;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    color: white;
    height: 100%;
    background-color: #1e1e24;
    font-size: 0.9rem;
    user-select: none;
}

.pointer {
    cursor: pointer;
}

img {
    width: 100%;
}

.i {
    img {
        display: block;
    }
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #9F8AD9;
}

::-webkit-scrollbar {
    border-radius: 8px;
    width: 8px;
    background-color: rgb(182, 182, 182);
}

canvas {
    position: relative;
}

.toggle {
    cursor: pointer;

    input {
        display: none;

        &:checked {
            &+label {
                &::after {
                    background-color: #97FF8F;
                    right: calc(100% + 4px);
                    transition: all .5s;
                }
            }
        }
    }

    label {
        cursor: pointer;
        font-weight: 300;
        position: relative;
        margin-left: 28px;
        display: flex;
        align-items: center;

        &::before {
            display: block;
            content: '';
            width: 20px;
            height: 8px;
            border-radius: 4px;
            background-color: #2A2D40;
            top: 50%;
            position: absolute;
            right: calc(100% + 4px);
            transform: translateY(-50%);
            transition: all .5s;
        }

        &::after {
            display: block;
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: white;
            position: absolute;
            right: calc(100% + 14px);
            transform: translateY(-50%);
            top: 50%;
            transition: all .5s;
        }
    }
}

.wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 35pt;
    background: rgb(16, 17, 24);
    background: linear-gradient(0deg, rgba(16, 17, 24, 1) 0%, rgba(76, 76, 76, 1) 100%);

    .container-flex {
        display: flex;
        height: 100%;
        width: 100%;
        padding: 0.6vw;
        user-select: none;

        .col {
            height: 100%;
            display: flex;
            flex-direction: column;

            .row {
                position: relative;
                padding-top: 8pt;

                &.rowfull {
                    height: 100%;
                    flex: 1;
                }

                .inner-row {
                    height: 100%;
                    width: 100%;

                    &.row-color {
                        background-color: #2A2D40;
                        border-radius: 8px;
                        padding: 8pt;
                    }

                    &.top {
                        background-color: #101118;
                        border-radius: 4px;
                        display: flex;
                        position: relative;

                        .play {
                            height: 100%;
                            width: 35pt;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0 10pt;
                        }

                        .l {
                            margin-left: 10pt;
                            padding: 4pt;
                            width: 200pt;
                            position: relative;

                            .select-arrow {
                                position: absolute;
                                right: 10pt;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                width: 10pt;
                                top: 0;

                                img {
                                    width: 100%;
                                }
                            }

                            .il {
                                border-radius: 4px;
                                background-color: #505050;
                                height: 100%;
                                width: 100%;
                                padding: 0 10pt;
                                display: flex;
                                align-items: center;
                                color: white;

                                input {
                                    color: $green;
                                    background-color: #333;
                                    width: 100%;
                                    height: 100%;
                                    padding: 0 10pt;
                                }
                            }

                            &.llabel {
                                width: auto;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .new {
                            margin-left: 0; // Asegura que no haya un margen lateral excesivo
                            padding: 6pt 0;
                            width: auto; // Ajusta automáticamente al contenido
                            position: relative;
                            display: flex; // Para alinear los elementos correctamente
                            align-items: center; // Alinea los elementos verticalmente
                            justify-content: center; // Centra horizontalmente los elementos dentro del contened

                            .btn {
                                background: #97FF8F;
                                color: black;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 0 10pt;
                                border-radius: 4pt;
                                height: 100%;
                                cursor: pointer;
                            }
                        }

                        .full {
                            position: absolute;
                            right: 3pt;
                            width: 35pt;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0 5pt;
                        }

                        .t,
                        .num {
                            font-size: 1rem;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .num {
                            font-size: 3rem;
                        }
                    }

                    &.content-labels,
                    &.content-graphs {
                        display: flex;
                        flex-direction: row;

                        .cl {
                            padding-right: 4pt;

                            .inner-content {
                                width: 100%;
                                height: 100%;
                                position: relative;
                            }

                            &:nth-child(1) {
                                width: 40pt;

                                .inner-content {
                                    border-radius: 4px;
                                    background: linear-gradient(180deg, #101118 0%, #363245 100%);
                                }
                            }

                            &:nth-child(2) {
                                width: 40pt;

                                .inner-content {
                                    border-radius: 4px;
                                    background-color: #101118;
                                }
                            }

                            &:nth-child(3) {
                                flex: 1;
                                padding-right: 0;

                                .inner-content {
                                    border-radius: 4px;
                                    background-color: #101118;
                                }
                            }
                        }
                    }

                    &.circular {
                        background-color: #101118;
                        border-radius: 4px 4px 100pt 100pt;
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        justify-content: space-around;

                        .disper {
                            height: 35vh;
                            padding: 8pt 12pt 32pt 35pt;
                            position: relative;

                            .top {
                                text-align: right;

                                .icon {
                                    width: 1rem;
                                    cursor: pointer;
                                }
                            }

                            .legend-x,
                            .legend-y,
                            .setLeg {
                                position: absolute;
                                font-size: 0.8rem;

                                input {
                                    background: black;
                                    color: #97FF8F;
                                    width: 75%;
                                    text-align: center;
                                    border-radius: 4pt;
                                    border: 1pt solid grey;
                                    z-index: 10;
                                    position: relative;
                                }
                            }

                            .legend-x {
                                bottom: 0;
                                left: 0;
                                right: 0;
                                width: 100%;
                                text-align: center;
                            }

                            .legend-y {
                                top: 45%;
                                left: 0;
                                transform: rotate(-90deg);
                            }

                            .setLegx {
                                bottom: 0;
                                right: 0;
                            }

                            .setLegy {
                                top: 0;
                            }
                        }

                        .alarm {
                            width: 48%;
                            margin: 0 auto;
                        }

                        .bimg {
                            width: 100pt;
                            margin: 0 auto;
                        }
                    }
                }
            }

            //Layouts Dimensions
            &:nth-child(1) {
                width: 70%;
                flex: 1;

                .row {
                    &:nth-child(1) {
                        height: 35pt;
                        padding-top: 0;
                    }

                    &:nth-child(2) {
                        height: 90pt;
                    }

                    &:nth-child(3) {
                        flex: 1;
                    }
                }
            }

            &:nth-child(2) {
                width: 25%;
                padding-left: 10pt;

                .row {
                    &:nth-child(1) {
                        height: 30pt;
                        padding-top: 0;
                    }

                    &:nth-child(2) {
                        height: 70pt;
                    }

                    &:nth-child(3) {
                        flex: 1;
                    }

                    &:nth-child(3) {
                        height: 40pt;
                    }
                }
            }
        }

        &.full {
            .col {
                &:first-child {
                    width: 100%;
                }

                &:last-child {
                    // display: none;
                }
            }
        }
    }
}

.zoomInOut {
    position: absolute;
    bottom: 10pt;
    right: 10pt;
    z-index: 80;

    .zoom {
        background: #826dbb;
        border-radius: 50%;
        width: 30pt;
        height: 30pt;
        margin-top: 6pt;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18pt;
        cursor: pointer;

        &:hover {
            background: #7e4fff;
        }
    }
}

//Content Labels & Content Graphs
.content-labels {
    display: flex;
    flex-direction: column;

    .tws {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        font-size: 0.7rem;

        .tp {
            line-height: 1.1;

            .sh {
                font-size: 1.6rem;
            }
        }

        .btm {
            font-size: 0.8rem;

            input {
                width: 100%;
                background: transparent;
                appearance: none;
                color: #97FF8F;
                font-size: 0.8rem;
                margin: 0;
                padding: 0;
                text-align: center;
            }
        }
    }

    .sections {
        display: flex;
        flex-direction: row;

        .label {
            width: 20%;
            padding: 8pt 4pt;

            .inner-label {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                font-size: 0.9rem;

                .tpl {
                    height: 50%;
                    // border-right: 1pt solid grey;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .s3 {
                        width: 25%;
                        text-align: center;
                        align-self: flex-end;
                        font-size: 0.8rem;

                        &.s3mid {
                            width: 50%;
                            border-radius: 0 0 2px 2px;
                            border-bottom: 1px solid #9F8AD9;
                            line-height: 1.1;
                            padding: 4pt 0pt;
                            font-size: 1rem;
                            position: relative;

                            .sel {
                                position: absolute;
                                width: 10pt;
                                right: 0;
                                top: 10pt;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                }

                                select {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    opacity: 0;
                                }
                            }
                        }

                        input {
                            background: transparent;
                            color: #97FF8F;
                            width: 75%;
                            text-align: center;
                            appearance: none;
                        }

                        &:first-child {
                            text-align: left;
                            padding-left: 4pt;
                        }

                        &:last-child {
                            text-align: right;
                            padding-right: 4pt;
                        }
                    }
                }

                &.red {
                    background: rgba(206, 17, 64, 0.86);
                    border-radius: 4pt;

                    .yellow-color,
                    .yellow-light-color {
                        color: white;
                    }
                }

                .btml {
                    height: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 2rem;
                }
            }
        }
    }
}

.out-of-bounds {
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0.86), rgba(255, 0, 0, 0));
}

.content-graphs {
    .legends {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 0.7rem;
    }

    .sections-graphs {
        background-color: transparent !important;
        display: flex;
        flex-direction: row;
        height: 100%;
        /* Asegura que ocupe el alto completo del contenedor */

        .graph {
            flex: 1; /* Ajusta automáticamente el ancho entre los gráficos */
            margin: 0 2px; /* Espaciado entre gráficos */
            position: relative; /* Posicionamiento relativo para ajustar */
            height: 100%; /* Ajusta al alto del contenedor padre */
            overflow: hidden; /* Evita que los elementos se desborden */
        }
    }
}

.inner-graph {
    background-color: #101118;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    div {
        height: 100%; /* Asegura que el div interno ocupe todo el espacio */
        width: 100%;
    }
    .graph-canvas {
        display: block;
        width: 100%;
        /* Sincronizado con el contenedor */
        height: 100%;
        /* Sincronizado con el contenedor */
    }

    svg {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
        z-index: 50;
    }
}

.bigGraph {
    padding: 30pt !important;
    position: relative;

    .l {
        position: absolute;

        &.ly {
            top: 8pt;
            width: 100%;
            text-align: center;
            left: 0;
            color: #F5AB24;
            font-size: 11pt;
        }

        &.lx {
            left: -13px;
            transform: rotateZ(-90deg);
            top: 50%;
            color: #F5AB24;
            font-size: 11pt;
        }
    }
}

.formation-box {
    height: 100%;
    overflow: hidden;

    .formation {
        display: flex;
        align-items: center;
        justify-content: center;

        .label {
            text-align: center;
            font-size: 7pt;
            background: black;
            padding: 1pt 2pt;
            border-radius: 3pt;
            color: white;
        }
    }
}

.selector {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    select {
        appearance: none;
        background: #1E1E24;
        border: 0;
        color: #97FF8F;
        padding: 4pt 20pt 4pt 4pt;
        border-radius: 4pt;
    }

    &.w {
        select {
            color: white;
        }
    }

    .arr {
        position: absolute;
        width: 8pt;
        right: 6pt;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.modal-lit-back {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 199;
}

.modal-lit {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 200;
    width: 100%;
    max-width: 600pt;
    height: 100%;
    background: #171923;
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;

    img {
        display: block;
    }

    .close-lit {
        position: absolute;
        right: 6pt;
        top: 6pt;
        width: 20pt;
        cursor: pointer;
    }

    .header-modal {
        display: flex;
        align-items: center;
        background: linear-gradient(180deg, #0E0F15 0%, #353145 100%);
        padding: 6pt 10pt;
        padding-top: 30pt;

        .t {
            flex: 1;
            font-size: 21pt;
        }

        .d {
            padding: 0 10pt;
            font-size: 16pt;

            span {
                font-size: 10pt;
            }
        }

        .i {
            width: 60pt;

            input {
                padding: 6pt 10pt;
                width: 100%;
                border-radius: 4px;
                background-color: #171923;
                color: white;
                text-align: right;
            }
        }
    }

    .sub-header {
        display: flex;
        align-items: center;
        padding: 6pt 10pt;
        position: relative;
        font-size: 12pt;

        .c {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;

            .ico {
                width: 25pt;
                margin-right: 6pt;
            }

            margin-right: 10pt;
            border-bottom: 1pt solid transparent;

            &:hover {
                border-bottom: 1pt solid #F5AB24;
            }

            input[type=file] {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0;
            }
        }

        .abs {
            position: absolute;
            right: 10pt;
            display: flex;
            align-items: center;
            color: #97FF8F;

            .cir {
                margin-left: 10pt;
            }
        }
    }

    .table {
        padding: 0 10pt;
        overflow: auto;
        padding-bottom: 120pt;

        table {
            width: 100%;
            border-collapse: collapse;
            border: 0;

            input,
            select {
                width: 100%;
                appearance: none;
                background: #1E1E24;
                border: 0;
                color: white;
                padding: 4pt 20pt 4pt 4pt;
                border-radius: 4pt;
            }

            thead {
                td {
                    color: #F5AB24;
                    font-size: 13pt;
                    text-align: center;
                    padding: 10pt 0;

                    span {
                        font-size: 10pt;
                    }
                }
            }

            td {
                white-space: nowrap;
                vertical-align: middle;

                .ico {
                    width: 15pt;
                }

                .it,
                .itn {
                    padding: 0pt 4pt;
                    height: 45pt;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .cir {
                        margin-right: 10pt;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding-bottom: 8pt;

                        &:nth-child(1) {
                            width: 10%;
                        }

                        &:nth-child(2) {
                            width: 30%;
                        }

                        &:nth-child(3) {
                            width: 15%;
                        }

                        &:nth-child(4) {
                            width: 15%;
                        }

                        &:nth-child(5) {
                            width: 10%;
                        }

                        &:nth-child(6) {
                            width: 10%;
                        }

                        &:nth-child(7) {
                            width: 10%;
                        }
                    }

                    .f {
                        border-radius: 4pt 0 0 4pt;
                    }

                    .ls {
                        border-radius: 0 4pt 4pt 0;
                    }

                    &:nth-child(1n) {
                        .it {
                            background: #2d2a3e;
                        }
                    }

                    &:nth-child(2n) {
                        .it {
                            background: #1f1a3d;
                        }
                    }

                    &:nth-child(3n) {
                        .it {
                            background: #31246d;
                        }
                    }
                }
            }

            .selector {
                width: 100%;

                select {
                    width: 100%;
                }
            }
        }
    }

    .bottom {
        position: absolute;
        bottom: 0;
        background: #101118;
        width: 100%;
        padding: 10pt;
        padding-bottom: 30pt;

        .r {
            display: flex;
            justify-content: flex-end;

            .btn {
                border-radius: 2px;
                background-color: #171923;
                padding: 4pt 20pt;
                border-radius: 4pt;
                cursor: pointer;

                &:first-child {
                    color: #C2AFF7;
                    margin-right: 10pt;
                }

                &:nth-child(2) {
                    margin-right: 10pt;
                }

                &:last-child {
                    color: #97FF8F;
                }
            }
        }
    }
}

.box-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    .popup {
        background: #2D2A3E;
        border-radius: 4pt;
        padding: 10pt 30pt;
        position: relative;

        .closep {
            width: 15pt;
            position: absolute;
            right: 10pt;
            top: 10pt;
            cursor: pointer;
        }

        .innerpopup {
            .t {
                text-align: center;
                color: #F36;
                font-size: 22pt;
                margin-bottom: 4pt;
            }

            .d {
                font-size: 14pt;
                margin-bottom: 20pt;
            }

            .btnview {
                background: #333;
                color: #97FF8F;
                padding: 4pt 10pt;
                border-radius: 4pt;
                width: 100pt;
                text-align: center;
                margin: auto;
                cursor: pointer;

                &:hover {
                    background: grey;
                }
            }
        }
    }
}

.cir {
    width: 10pt;
    height: 10pt;
    border-radius: 50%;
    background: #97FF8F;
    cursor: pointer;
}

.btn-gradient {
    border-radius: 4px;
    background: linear-gradient(180deg, #2A2D40 0%, #101118 100%);
}

.realCircle {
    fill: transparent;
    stroke: #F5AB24;
    stroke-width: 2;
    stroke-linecap: square;
    stroke-dasharray: 1, 4;
    stroke-dashoffset: 3;
}

.realInnerCircle {
    fill: #97FF8F;
    border: 1px solid #583BD4;
}

@-webkit-keyframes flash {

    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@keyframes flash {

    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.flash {
    animation: 1s flash infinite;
}

.disable {
    user-select: none;
    pointer-events: none;
    filter: grayscale(1);
}

.grey {
    filter: grayscale(1);
    /* Aplica gris cuando está desactivado */
}

.editing {
    .red {
        background: transparent !important;
    }

    .yellow-color,
    .yellow-light-color {
        color: #F5AB24 !important;
    }
}

.areagraph {
    // fill: #5d40da52;
    stroke: none;
    fill: url(#rect-gradient);
}

.circledisper {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, .3));
}

.gradientdiv {
    background: linear-gradient(90deg, #583BD4 0%, rgba(14, 15, 21, 0.03) 43%, rgba(58, 52, 80, 0) 61%, #583BD4 100%);
}

.tooltip {
    background: #101118;
    position: fixed;
    top: 50%;
    left: 50%;
    box-shadow: 3px 0 8px 0 #583BD4;
    border-radius: 4pt;
    padding: 10pt;
    z-index: 99;
    min-width: 100pt;
    min-height: 100pt;
    width: 350pt;

    .innertooltip {
        font-size: 1.1rem;

        /* Incrementa el tamaño de la fuente general */
        span {
            font-size: .9rem;
            /* Incrementa el tamaño de la fuente de los spans */
        }

        .tp,
        .bt {
            margin-bottom: 20pt;

            .r {
                display: flex;
                align-items: center;

                .t {
                    margin-right: 5pt;
                    /* Reduce el margen derecho */
                    color: #FA9917;
                    font-size: 1.1rem;
                    /* Ajusta el tamaño de la fuente */
                }

                .n {
                    color: #97FF8F;
                    font-size: 1.1rem;
                    /* Ajusta el tamaño de la fuente */
                }
            }
        }

        .ttt {
            color: #FA9917;
            margin-top: 20pt;
        }

        .tabl {
            display: flex;
            width: 100%;

            /* Ajusta el ancho para que ocupe el espacio disponible */
            .col {
                text-align: left;
                /* Asegura que el texto esté alineado a la izquierda */
                padding-right: 10pt;

                /* Espacio entre columnas */
                .t {
                    color: #FA9917;
                    margin-bottom: 10pt;
                }

                &:first-child {
                    width: 40%;

                    .tt {
                        color: white;
                    }
                }

                &:nth-child(2) {
                    width: 25%;

                    .tt {
                        color: #9F8AD9;
                    }
                }

                &:last-child {
                    width: 35%;

                    .range {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;

                        .tv {
                            margin: 0 10pt;
                            width: 10%;
                            height: 15pt;
                            background: linear-gradient(90deg, #583BD4 0%, rgba(14, 15, 21, 0.03) 43%, rgba(58, 52, 80, 0) 61%, #583BD4 100%);
                        }

                        .tt {
                            color: #fff;
                            font-size: 0.9rem;
                            /* Ajusta el tamaño de la fuente */
                            width: 40%;
                        }
                    }
                }
            }
        }
    }
}





.legend-scroll {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 10;

    .scroller {
        position: absolute;
        width: 100%;
        height: 50%;
        background: rgb(255 255 255 / 16%);
        border-radius: 7pt;
        top: 0;
        box-shadow: inset 0 0 14pt rgb(0 0 0 / 31%);
        cursor: grab;
        overflow: hidden;
    }
}

.polygonmain {
    // filter: drop-shadow(0px 0 11px rgba(86, 50, 184, 0.2));
    stroke: #2a1d68;
    stroke-width: 1pt;
}

.tooltip,
.generaltooltip {
    position: absolute;
    z-index: 999;
    border-radius: 9px;
    background-color: #101118;
    box-shadow: 1px 0 4px 0 #C2AFF7;
    pointer-events: none;
    padding: 10pt;
    font-size: 0.9rem;
}

.toggleVibration {
    width: 35px;
    /* Forza el ancho */
    height: 35px;
    /* Forza el alto */
    color: white;
    /* Color predeterminado */
    display: inline-block;
    /* Asegura que ocupe espacio */
    font-size: 35px;
    /* Tamaño del icono */
    line-height: 35px;
    /* Ajuste del alto de línea */
    text-align: center;
    /* Centra el icono */
}

.toggleVibration.active {
    color: #97ff8f;
    /* Color verde cuando está activo */
}